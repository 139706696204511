import { render, staticRenderFns } from "./SearchSidebar.vue?vue&type=template&id=df15c8ee&scoped=true&"
import script from "./SearchSidebar.vue?vue&type=script&lang=ts&"
export * from "./SearchSidebar.vue?vue&type=script&lang=ts&"
import style0 from "./SearchSidebar.vue?vue&type=style&index=0&id=df15c8ee&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df15c8ee",
  null
  
)

export default component.exports